<template>
  <v-dialog v-model="active" max-width="500" @click:outside="close">
    <template v-for="slot of Object.keys($slots)" v-slot:[slot]>
      <slot :name="slot" />
    </template>

    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }">
        <v-btn color="primary" small v-bind="attrs" v-on="on">{{ buttonText }}</v-btn>
      </slot>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">{{ buttonText }}</span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="selectedBonusType"
              label="Тип бонуса"
              :items="bonusTypes"
              item-value="id"
              item-text="name"
              :disabled="!isGlobalAvailable"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="model.amount"
              :error-messages="inputErrors()"
              suffix="грн."
              type="number"
              @blur="$v.model.amount.$touch()"
              @input="$v.model.amount.$touch()"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="yesClicked">Так</v-btn>
        <v-btn text @click="close">Нi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { minValue } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'BonusPayroll',

  mixins: [validationMixin],

  emits: ['yes'],

  props: {
    userId: {
      type: Number,
      required: true,
    },
    bonusActionType: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      active: false,
      model: {
        amount: 0,
      },
      selectedBonusType: 1,
      bonusTypes: [
        { id: 1, name: 'Бонус компанії' },
        { id: 2, name: 'Глобальний бонус' },
      ],
    }
  },

  validations() {
    return {
      model: {
        amount: { minValue: minValue(1) },
      },
    }
  },

  computed: {
    ...mapState('profile', ['userRoles']),
    ...mapState('dictionaries', ['rolesDictionary']),

    currentCompanyId() {
      return this.$route.params.id
    },
    isGlobalAvailable() {
      return this.userRoles.includes(this.rolesDictionary.ADMIN) || this.userRoles.includes(this.rolesDictionary.SUPPORT)
    },
  },

  methods: {
    ...mapActions('bonuses', ['plusUserBonuses', 'minusUserBonuses']),

    inputErrors() {
      const errors = []
      if (!this.$v.model.amount.$dirty) return errors
      this.$v.model.amount.minValue === false && errors.push(`Введіть значення від ${this.$v.model.amount.$params.minValue.min}`)
      return errors
    },

    close() {
      this.model.amount = 0
      this.$v.$reset()
      this.active = false
    },

    async yesClicked() {
      this.$v.model.$touch()
      if (this.$v.$anyError) return
      const scope = this.selectedBonusType === 1 ? 'local' : 'global'
      const payload = {
        userId: this.userId,
        companyId: this.currentCompanyId,
        amount: +this.model.amount,
        scope,
      }
      if (this.bonusActionType === 'plusBonus') {
        await this.plusUserBonuses(payload)
      } else if (this.bonusActionType === 'minusBonus') {
        await this.minusUserBonuses(payload)
      } else return
      this.$emit('yes')
      this.close()
    },
  },
}
</script>
