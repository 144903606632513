<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsMeta.total_count || options.itemsPerPage * totalPages"
        :footer-props="footerProps"
      >
        <template v-for="slot of Object.keys($slots)" v-slot:[slot]>
          <slot :name="slot" />
        </template>

        <template v-slot:top>
          <slot name="top">
            <FormToolbar :title="title" :with-search="withSearch" @search="(val) => (searchText = val)" />
          </slot>
        </template>

        <template v-for="(col, index) in headers.map((head) => head.value)" v-slot:[`header.${col}`]="{ header }">
          <slot :name="`header.${col}`" :header="header">
            <TableTitleDateFilter
              v-if="dateFilterFields.includes(col)"
              :title-text="header.text"
              @select="(selected) => (selectedDates = selected)"
              @ok="applyDateFilter('last_payment_date', selectedDates)"
            />
            <span v-else :key="index">{{ header.text }}</span>
          </slot>
        </template>

        <template v-for="col in headers.map((head) => head.value)" v-slot:[`item.${col}`]="{ item }">
          <slot :name="`item.${col}`" :item="item">{{ item[col] }}</slot>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import FormToolbar from '@/components/common/forms/FormToolbar'
import sortUtils from '@/mixins/sortUtils'
import searchUtils from '@/mixins/searchUtils'
import filterUtils from '@/mixins/filterUtils'
import TableTitleDateFilter from '@/components/common/filters/TableTitleFilter/TableTitleDateFilter.vue'

export default {
  name: 'TableForm',

  components: { TableTitleDateFilter, FormToolbar },

  mixins: [sortUtils, searchUtils, filterUtils],

  props: {
    title: {
      type: String,
      required: true,
    },

    headers: {
      type: Array,
      required: true,
    },

    withSearch: {
      type: Boolean,
      default: false,
    },

    defaultSearch: {
      type: Boolean,
      default: false,
    },

    searchFields: {
      type: Array,
      default: () => [],
    },

    dateFilterFields: {
      type: Array,
      default: () => [],
    },

    items: {
      type: Array,
      required: true,
    },

    itemsMeta: {
      type: Object,
      required: true,
    },

    loadFunction: {
      type: Function,
      required: true,
    },

    payloadPreset: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loading: false,
      options: {},
      searchText: '',
      payload: {},
      totalPages: 2,
    }
  },

  computed: {
    footerProps() {
      return {
        'items-per-page-options': [5, 10, 15, 20],
        'show-current-page': true,
        'show-first-last-page': !!this.itemsMeta.total_count,
        ...(!this.itemsMeta.total_count && { 'page-text': '{0}-{1}' }),
      }
    },
  },

  watch: {
    options: {
      async handler(val) {
        if (!this.itemsMeta.total_count && val.page === this.totalPages) {
          this.totalPages++
        }
        await this.paginateTo()
      },
      deep: true,
    },

    payloadPreset: {
      async handler(val) {
        this.payload = { ...val }
      },
      deep: true,
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.payload = { ...this.payloadPreset }
    },
    async paginateTo() {
      this.loading = true
      this.payload = {
        ...this.payload,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        sort: this.sortObject,
        search: this.defaultSearch ? this.searchObject : this.searchText,
        ...this.filterObject,
      }
      try {
        await this.loadFunction(this.payload)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
